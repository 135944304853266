.diagonal {
  background: linear-gradient(
    to top right,
    #efefef calc(50% - 1px),
    black,
    #efefef calc(50% + 1px)
  );
}

.wrap{
   word-wrap:break-word;
}

.button {
  display: inline-block;
  border: none;
  color: #ffffff;
  text-align: center;
  transition: all 0.5s;
  cursor: pointer;
}

.button span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.button span:after {
  content: "\00bb";
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.button:hover span {
  padding-right: 15px;
}

.button:hover span:after {
  opacity: 1;
  right: 0;
}

.PointButton:hover {
  color: lightblue;
  text-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: color 0.5s ease-in-out;
}

.scroll-to-top {
  position: fixed;
  bottom: 12px;
  left: 50%;
  width: 40px;
  height: 39px;
  border-radius: 100%;
  background: #d61414;
  color: #ffffff;
  z-index: 1;
}

.BettingTypebutton {
  color: white;
  font-weight: bold;
  font-size: 18px;
}

.bettingFunctionsButton {
  text-align: center;
  color: white;
  font-weight: bold;
}

.AllRedNumber {
  padding: 18px;
  font-weight: bold;
  color: red;
  font-size: 20px;
  cursor: pointer;
  border: 0.5px solid black;
  border-radius: 5px;
}

.AllRedNumber:hover {
  box-shadow: 0 0 4px rgb(82 76 76 / 100%);
  background-color: lightgray;
}

.AllRedNumcontainer {
  flex-wrap: wrap;
  padding: 20px;
  overflow-x: auto;
}

.AllRedNumberTitle {
  color:rgb(4, 0, 255);
  font-weight: bold;
}

.scrollDesign {
  position: fixed;
  bottom: 20px;
  right: 3%;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background: #d61414;
  color: #ffffff;
  z-index: 1;
}

.iconpopup {
  animation: scrollTopPOP 0.5s alternate ease;
}

.icon {
  /* margin-top: 50px; */
  animation: scrollTop 0.5s ease infinite alternate;
}

@keyframes scrollTopPOP {
  from {
    transform: translateY(4px);
  }
  to {
    transform: translateY(-4px);
  }
}

@keyframes scrollTop {
  from {
    transform: translateY(2px);
  }
  to {
    transform: translateY(-2px);
  }
}

.darkMode-wrap {
  z-index: 1000 !important;
  position: fixed;
  left: 0;
  bottom: 5%;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
  background-color: var(--gfg-selected-color);
  box-shadow: 0 0 4px rgb(82 76 76 / 50%);
  transition: all 0.2s ease-in;
  cursor: pointer;
}

.darkMode2-wrap {
  z-index: 1000 !important;
  position: fixed;
  position: fixed;
  left: 50%;
  bottom: 5%;
  border-radius: 50%;
  background-color: white;
  box-shadow: 0 0 4px rgb(82 76 76 / 50%);
  transition: all 0.2s ease-in;
  cursor: pointer;
}

.containers::-webkit-scrollbar {
  display: none; /* Safari and Chrome browsers */
}

.containers {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* mozilla */
}
.containers {
  width: 200px;
  height: 550px;
  overflow-y: scroll;
}

.form-check input[type="checkbox"] {
  height: 20px;
  width: 20px;
  position: relative;
  margin: 0px 5px 0px 0px;
}

.App {
  text-align: center;
}

.NumPadContainer {
  flex-direction: "row";
  width: "100%";
  flex-wrap: "wrap";
}

.row {
  flex-direction: "row";
}

.column {
  flex-direction: "column";
}

.LineLabel {
  width: "25%";
  font-size: 15;
  text-align: "center";
  border-width: 0.5;
  border-color: "black";
  justify-content: "center";
  align-self: "center";
  background-color: "white";
  height: 35;
  padding: 7;
}

.dcc {
  display: flex;
  align-items: center;
  justify-content: center;
}

.LoginInputText {
  text-align: center;
  height: 30px;
  /* border-radius: 20px; */
}

.TitleUsername {
  font-weight: bold;
  font-size: 18px;
  width: 100px;
  margin-left: "4.5%";
  margin-right: 50px;
  font-family: serif;
}

.TitleUsernameMobile {
  font-weight: bold;
  font-size: 18px;
  width: 100px;
  /* margin-left: "4.5%";
  margin-right: 50px; */
}

.DropdownListDesign {
  text-align: center;
  margin-left: 100px;
}

.DropdownListBoxV {
  text-align: center;
  font-size: 14px;
  width: 100px;
}

.number5Dborderdspecon {
  padding: 10px 0;
  border-radius: 20px;
  background-color: #eeeeee;
  font-size: 20px;
}

.FirstReportTable {
  width: 20%;
  border: 1px solid black;
  min-width: 150px;
}

.FirstReportTotalTable {
  width: 20%;
  border: 1px solid black;
  min-width: 150px;
  background-color: #000;
}

.logoimages {
  width: 350;
  height: 300;
  border-radius: 50px;
}

.loadingimages {
  width: 250;
  height: 200;
  border-radius: 50px;
}

.loadingimagesbet {
  width: 180;
  height: 150;
  border-radius: 50px;
}

.loadingreceiptimages {
  /* width: 100;
  height: 50; */
  border-radius: 50px;
}

.zodiacspaces {
  margin-top: auto;
}

.smallerAnimallogo {
  width: 20px;
  height: 20px;
}

.Animallogo2 {
  width: 90px;
  height: 90px;
}

.zodiacfont {
  padding: 6px 0;
  font-size: 18px;
  color: white;
  background-color: #ff431b;
  border-radius: 10px;
  font-weight: bold;
}

.logoiconimages {
  width: 80px;
  height: 80px;
}

.RedNumberborder {
  padding: 30px 0;
  border-width: "10px";
  border-radius: 8px;
  background-color: #3c96f4;
  border-color: #3c96f4;
  font-size: 18px;
  height: 125px;
}

.loadingMessage {
  font-size: 40px;
  font-weight: bold;
  margin-top: 10px;
}

.loadingMessageBet {
  font-size: 35px;
  font-weight: bold;
  margin-top: 10px;
}

.divLoader {
  width: 100vw;
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.RedNumberborderdetails {
  padding: 10px 0;
  border-width: "10px";
  border-radius: 8px;
  background-color: #3c96f4;
  border-color: #3c96f4;
  font-size: 18px;
}

.americaborder {
  padding: 10px 0;
  border-radius: 8px;
  background-color: #6d100e;
  font-size: 18px;
}

.sabahborder {
  padding: 10px 0;
  border-radius: 8px;
  background-color: #fc0404;
}

.sabahborderclass {
  border-radius: 8px;
  background-color: #fc0404;
  height: 40px;
  font-size: 18px;
}

.sarawakborderclass {
  border-radius: 8px;
  background-color: #008000;
  height: 40px;
  font-size: 18px;
}

.sarawakborder {
  padding: 10px 0;
  border-radius: 8px;
  background-color: #008000;
}

.singaporeborder {
  padding: 10px 0;
  border-radius: 8px;
  background-color: #32c6f4;
}

.bordersingaporeclass {
  border-radius: 8px;
  background-color: #32c6f4;
  height: 40px;
  font-size: 18px;
}

.americaborderclass {
  border-radius: 8px;
  background-color: #6d100e;
  height: 40px;
  font-size: 18px;
}

.draganborder {
  padding: 10px 0;
  border-radius: 8px;
  background-color: #c1272d;
}

.dragonborderclass {
  border-radius: 8px;
  background-color: #c1272d;
  height: 40px;
  font-size: 18px;
}

.sandagenborderclass {
  border-radius: 8px;
  background-color: #f8c100;
  height: 40px;
  font-size: 18px;
}

.BetNumberButton {
  width: "100%";
  border-radius: 6px;
  border-width: 0px;
  background-color: #ffffff;
}

.BettingInputSelectedContainer {
  flex-direction: "row";
  align-content: "center";
  width: "100%";
  background-color: "white";
  border-width: 1;
  border-color: "#8a8a8a";
}

.ReduceButton {
  width: 25px;
  border-radius: 6px;
  border-width: 0px;
  background-color: #3c96f4;
}

.HitButton {
  width: 100%;
  height: auto;
  padding: 10px;
  border-radius: 6px;
  border-width: 0px;
  background-color: #3c96f4;
}

.HitButtonSgd {
  width: 100%;
  height: auto;
  padding: 10px;
  border-radius: 6px;
  border-width: 0px;
  background-color: #32C6F4;
}

.HitButtonRed {
  width: 100%;
  height: auto;
  padding: 10px;
  border-radius: 6px;
  border-width: 0px;
  background-color: #ee3e13;
}

.HitButtonThai {
  width: 100%;
  height: auto;
  padding: 10px;
  border-radius: 6px;
  border-width: 0px;
  background-color: #0E189F;
}

.IBoxButton {
  width: 100%;
  height: auto;
  padding: 10px;
  border-radius: 6px;
  border-width: 0px;
  background-color: #3c96f4;
}

.DateCheckmarkIcon {
  border-radius: 10;
  border-color: "black";
  width: 10;
  height: 10;
  margin-right: 15;
}

.DateSelectButton {
  height: 45px;
  /* border-radius: 6px; */
  border-width: 1px;
  background-color: #ffffff;
}

.ResultButton {
  width: 50px;
  height: 38px;
  border-radius: 6px;
  border-width: 0.5px;
}

.sandagenborder {
  padding: 10px 0;
  border-radius: 8px;
  background-color: #f8c100;
}

.BettingResultButtonViews{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.BettingResultMiddleR{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.DisplayBettingFormat{
  width: 50%;
  height: 40%;
  font-weight: bold;
  margin-top: 20;
}

.BettingResultButtonCSS{
  width: 100%;
  height: auto;
  text-align: center;
  font-weight: bold;
  background-color: #F6F6F6;
  border-radius: 6px;
  border-width: 0.5px;
}

.Bettingv2PlatformIcon:hover {
  background-color: #555555;
  box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.616);
  color: #fff;
  border-radius: 80px;
  transform: translateY(-7px);
}

.mobileCardLogo2 {
  width: 130px;
  height: 70px;
  justify-content: center !important;
}

.Button {
  margin-top: 40px;
  width: 300px;
  height: 45px;
  border-radius: 50px;
  border-width: 1;
  background-color: #ffbe00;
  border-color: #f07f15;
  justify-content: center;
}

.ButtonLogin {
  margin-top: 40px;
  width: 200px;
  height: 45px;
  border-radius: 5px;
  border-width: 1;
  background-color: black;
  border-color: lightblue;
  justify-content: center;
  /* animation-name: rotate;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-play-state: running; */
}

/* .ButtonLogin:hover {
  animation-play-state: paused;
}

@keyframes rotate {
  0% {
    transform:scale(0);
  }
  100% {
    transform: scale(360deg);
  }
} */

/* .TitleName {
  animation: myfirst 5s linear 2s infinite alternate;
}

@keyframes myfirst {
  0%   {background-color:rgb(255, 250, 250); left:0px; top:0px;}
  25%  {background-color:rgb(0, 102, 255); left:200px; top:0px;}
  50%  {background-color:blue; left:200px; top:200px;}
  75%  {background-color:green; left:0px; top:200px;}
  100% {background-color:rgb(255, 166, 0); left:0px; top:0px;}
} */

.backgroundImage4 {
  background-image: url(./Images/lightpurple_background.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: #ccccff;
}

.backgroundImageLogin {
  background-image: url(./Images/Testing.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.magnumborder {
  padding: 10px 0;
  border-radius: 8px;
  background-color: #fff23e;
}

.BigSmallButton {
  border: 1px solid black;
  border-radius: 5px;
  width: 50px;
  cursor: pointer;
  font-weight: bold;
  margin-left: 5px;
}

.textalignment {
  margin-top: 30px;
  font-size: 21px;
}

.Headerlogo {
  width: 200px;
  height: 90px;
}

.mobileHeaderlogo {
  height: 60px;
  width: 80px;
}

.navbardesign {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0;
  z-index: 999;
}

.textalignment2 {
  margin-top: 15px;
}

.logomagnumimages {
  width: 150px;
  height: 80px;
}

.mobileCardLogo {
  width: 140px;
  height: 80px;
}

.bordermagnumclass {
  border-radius: 8px;
  background-color: #fff23e;
  height: 40px;
  font-size: 18px;
}

.numberborder {
  padding: 10px 0;
  border-radius: 8px;
  background-color: #eeeeee;
  /* height: 30px; */
}

.damacaiborderclass {
  border-radius: 8px;
  background-color: #414bb2;
  height: 40px;
  font-size: 18px;
}

.damacaiborder {
  padding: 10px 0;
  border-radius: 8px;
  background-color: #414bb2;
}

.backgroundImage {
  background-image: url(./Images/lightpurple_background.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  /* background-color: #000000; */
}

.mobileHeader {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
}

.datePickerButton {
  background-color: #9dcefe;
  border-radius: 8px;
  border-color: #5eadff;
  width: 80%;
  outline: none;
  padding: 5px 5px;
}

.ShowReceiptResultButton {
  background-color: #9dcefe;
  border-radius: 8px;
  border-color: #5eadff;
  width: 50%;
  outline: none;
  padding: 5px 5px;
}

.datePickerButtonMobile {
  background-color: #9dcefe;
  border-radius: 8px;
  border-color: #5eadff;
  width: 50%;
  outline: none;
  padding: 5px 5px;
}

.languagePicker {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: white;
  margin: 0px;
  margin-right: 20px;
}

.languagePicker label {
  margin-bottom: 0;
  margin-right: 10px;
}

.languagePicker .form-control {
  width: fit-content;
  height: fit-content;
  padding: 0px 10px;
}

.mobiledatefont {
  font-size: 15px !important;
  font-weight: bold !important;
}

.mobileToggleBar {
  margin-top: 20px;
  margin-bottom: 10px;
}

.activeResult {
  background-color: white;
  border: 5px solid rgb(60, 252, 60);
  border-radius: 50%;
  -webkit-filter: grayscale(0%) !important;
  filter: grayscale(0%) !important;
}

.activeResultnormal {
  background-color: white;
  border-radius: 50%;
  -webkit-filter: grayscale(0%) !important;
  filter: grayscale(0%) !important;
}

.mobileCardContainer {
  margin: 0px !important;
  width: "100%" !important;
}

.textalignmen3t {
  padding: 10px 0;
  font-size: 21px;
  font-weight: bold;
}

.numberbordergorjackport {
  border-radius: 0px;
  background-color: #eeeeee;
  font-size: 24px;
}

.americaborder5D2 {
  padding: 10px 0;
  /* border-radius: 8px; */
  background-color: #6d100e;
  font-size: 18px;
}

.gdborder5D2 {
  padding: 10px 0;
  /* border-radius: 8px; */
  background-color: #c1272d;
  font-size: 18px;
}

.jackpotborderRedBet {
  border-radius: 5px;
  height: 45px;
  background-color: #ee3e13;
  width: 100%;
  color: white;
  font-size: 20px;
}

.jackpotborderSgd {
  border-radius: 5px;
  height: 45px;
  background-color: #32C6F4;
  width: 100%;
  color: white;
  font-size: 20px;
}

.jackpotborderThai {
  border-radius: 5px;
  height: 45px;
  background-color: #0E189F;
  width: 100%;
  color: white;
  font-size: 19px;
}

.mamama {
  width: "90%";
}

.totoborder {
  padding: 10px 0;
  border-radius: 8px;
  background-color: #ff431b;
}

.totoborderclass {
  border-radius: 8px;
  background-color: #ff431b;
  height: 40px;
  font-size: 18px;
}

.nineborder {
  padding: 10px 0;
  border-radius: 8px;
  background-color: #ffc107;
}

.nineborderclass {
  border-radius: 8px;
  background-color: #ffc107;
  height: 40px;
  font-size: 18px;
}

.mainPageToggleLogo {
  height: 54px;
  width: 54px;
  cursor: pointer;
  -webkit-filter: grayscale(100%);
  filter: grayscale(1000%);
}

.numberborderdspecon {
  padding: 10px 0;
  border-radius: 20px;
  background-color: #eeeeee;
  font-size: 23px;
}

.numberborderHome {
  padding: 10px 0;
  border-radius: 20px;
  background-color: #ffffff;
  font-size: 24px;
}

.dateButton {
  background-color: #9dcefe;
  border-radius: 8px;
  border-color: #5eadff;
  outline: none;
  padding: 5px 5px;
}

.Marginout {
  margin: 0 140px;
  flex-direction: row;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/* Magnum */

.Magnumtittle {
  font-size: 40px;
  font-weight: bold;
  text-align: center;
  color: black;
  background-color: rgba(255, 230, 0, 0.658);
  border: solid black;
}

.plusbtn {
  font-size: 27px;
  background-color: red;
  text-align: center;
  border-color: rgb(0, 0, 0);
  border-style: solid;
}

.or {
  font-size: 30px;
  text-align: center;
  font-weight: bold;
}

.CloseButton:hover {
  box-shadow: 0 0 30px rgb(82 76 76 / 50%);
}

/* Damacai */

.Damacaitittle {
  font-size: 40px;
  font-weight: bold;
  text-align: center;
  color: rgb(255, 255, 255);
  background-color: rgb(31, 33, 163);
  border: solid black;
}

/* ToTo */
.Tototittle {
  font-size: 40px;
  font-weight: bold;
  text-align: center;
  color: rgb(255, 255, 255);
  background-color: rgb(194, 25, 25);
  border: solid black;
}

.picture {
  height: 150;
  width: 85;
  border: solid red;
}

/* Singapora */
.Sgtittle {
  font-size: 40px;
  font-weight: bold;
  text-align: center;
  color: rgb(15, 15, 15);
  background-color: rgb(34, 201, 243);
  border: solid black;
}

/* BoomTown */
.Boomtowntittle {
  font-size: 40px;
  font-weight: bold;
  text-shadow: -3px 0 rgb(255, 255, 255), 0 2px rgb(255, 255, 255),
    1px 0 rgb(255, 255, 255), 0 -1px rgb(255, 255, 255);
  font-family: sans;
  color: rgb(255, 0, 0);
  text-align: center;
  background-color: rgb(0, 0, 0);
  border: solid rgb(0, 0, 0);
}

/* Grand */
.GDtittle {
  font-size: 40px;
  font-weight: bold;
  text-shadow: -3px 0 rgb(255, 255, 255), 0 2px rgb(255, 255, 255),
    1px 0 rgb(255, 255, 255), 0 -1px rgb(255, 255, 255);
  font-family: sans;
  color: rgb(255, 0, 0);
  text-align: center;
  background-color: rgb(0, 0, 0);
  border: solid rgb(0, 0, 0);
}

/* Sabah88 */
.Sabahtittle {
  font-size: 40px;
  font-weight: bold;
  text-align: center;
  color: rgb(255, 255, 255);
  background-color: rgb(4, 0, 255);
  border: solid black;
}

/* Stc */
.Stctittle {
  font-size: 40px;
  font-weight: bold;
  text-align: center;
  color: rgb(34, 116, 34);
  background-color: rgb(255, 187, 0);
  border: solid black;
}

/* BoomTown */
.Ninelottotittle {
  font-size: 40px;
  font-weight: bold;
  text-shadow: -3px 0 rgb(255, 255, 255), 0 2px rgb(255, 255, 255),
    1px 0 rgb(255, 255, 255), 0 -1px rgb(255, 255, 255);
  font-family: sans;
  color: rgb(255, 115, 0);
  text-align: center;
  background-color: rgb(0, 0, 0);
  border: solid rgb(0, 0, 0);
}

/* Cash */
/* .Cashtittle {
  font-size: 40px;
  font-weight: bold;
  text-align: center;
  color: rgb(255, 255, 255);
  background-color: rgb(34, 116, 34);
  border: solid black;
} */

.buttonHard {
  width: 80px;
  height: 45px;
  font-size: 15px;
  /* padding: 2px; */
  margin-right: 2px;
  margin-bottom: 1px;
  text-transform: uppercase;
  font-weight: 520;
  border: 1px solid rgb(236, 236, 236);
  border-radius: 5px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  background-color: #e62416;
  color: #fff;
  /* outline: none; */
  }
  .buttonHard:hover {
    background-color: #585858;
    box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.616);
    color: #fff;
    transform: translateY(-7px);
  }

.buttonEzandHard {
  width: 80px;
  height: 45px;
  font-size: 15px;
  /* padding: 2px; */
  margin-right: 2px;
  margin-bottom: 1px;
  text-transform: uppercase;
  font-weight: 520;
  border: 1px solid rgb(236, 236, 236);
  border-radius: 5px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  background-color: #007bff;
  color: #fff;
  /* outline: none; */
  }
  .buttonEzandHard:hover {
    background-color: #585858;
    box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.616);
    color: #fff;
    transform: translateY(-7px);
  }
  

.buttonUSerList {
  width: 30%;
  height: 100px;
  font-size: 15px;
  /* padding: 2px; */
  margin-right: 2px;
  margin-bottom: 1px;
  text-transform: uppercase;
  font-weight: 520;
  border: 1px solid rgb(236, 236, 236);
  border-radius: 5px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  /* outline: none; */
  }

.buttonUSerList:hover {
  background-color: #585858;
  box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.616);
  color: #fff;
  transform: translateY(-7px);
}

.border {
  background-color: rgb(104, 104, 104);
  color: white;
  font-weight: bold;
  font-size: 25px;
  text-align: center;
}

.border3 {
  background-color: none;
  font-weight: 500;
  text-align: center;
  color: black;
  font-size: 25px;
  border: (204, 204, 204);
}

.UnApproveTableDesign {
  font-weight: bold;
  border: 1px solid black;
  padding: 4px;
}

.UnApproveDesign {
  display: flex;
  flex-direction: row;
  width: 100%;
  text-align: center;
}

.resulttop {
  font-size: 28px;
  color: #303030;
  font-weight: 700;
  text-align: center;
  border-color: #ccc;
  border-style: solid;
  border-width: 1px;
  padding: 2px 0;
}

.resultbottom {
  text-align: center;
  font-size: 24px;
  color: #000;
  width: 20%;
  border-color: #ccc;
  border-style: solid;
  border-width: 1px;
  padding: 3px 0;
  font-weight: bold;
}

.BackgroundColorM {
  background-color: #f0e876;
}

.BackgroundColorK {
  background-color: #8c94f0;
}

.BackgroundColorT {
  background-color: #f55e5e;
}

.BackgroundColorS {
  background-color: #68d6f8;
}

.BackgroundColorW {
  background-color: #3c943c;
}

.BackgroundColorB {
  background-color: #f58065;
}

.BackgroundColorD {
  background-color: #f1cd4a;
}

.BackgroundColor8 {
  background-color: red;
}

.BackgroundColor9 {
  background-color: #ffc107;
}


.setBoostrap {
  position: absolute;
  width: 1800px;
  left: -330px;
}

.setBoostrapSmall {
  position: absolute;
  width: 1500px;
  left: -50px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.footer {
  margin-top: 1rem;
  padding: 1rem;
  background-color: #474747;
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  padding-bottom: 0px;
  width: 100%;
}

.App-link {
  color: #61dafb;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.dropdown-toggle,
.dropdown-menu {
  width: 100%;
}

.form-check input[type="checkbox"] {
  height: 20px;
  width: 20px;
  position: relative;
  margin: 0px 5px 0px 0px;
}

.BetN {
  color: blue;
  font-weight: bold;
  font-size: 17px;
}

.BetNFormatDisplayNumber {
  font-size: 12px;
  top: 0;
  left: 0;
  color: red;
  font-weight: bold;
}

.BoxVersionKeyBettingInput {
  border-width: 1;
  width: 100px;
  color: red;
  font-weight: bold;
  text-align: center;
}

.StyleDatePickerMobile {
  border: 1px solid #b9b9b9;
  font-size: 15px;
  text-align: center;
  width: 100%;
  padding: 4px;
}

.StyleDatePicker {
  border: 1px solid #cecece;
  text-align: center;
  width: 100%;
}

.ReportDetails {
  color: black;
  position: relative;
}

.FileInput {
  display: none;
}

.Dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #747171;
  border-style: dashed;
  background-color: #fafafa;
  color: #747171;
  outline: none;
  transition: border 0.24s ease-in-out;
}

.containerOndrop {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
}

.button2:hover {
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
}

.DropzoneNews {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #747171;
  border-style: dashed;
  background-color: #fafafa;
  color: #747171;
  outline: none;
  transition: border 0.24s ease-in-out;
}

.BettingV2Width {
  width: 580px;
}

.Highlight {
  background-color: rgb(188, 185, 236);
}

.DisplayEditData {
  font-size: 16px;
  padding: 5px;
  text-align: start;
}

.BettingFontSize {
  font-size: 20px;
}

.ViewReceiptFont {
  font-size: 20px;
  font-weight: bold;
  width: 20%;
}

@keyframes Animation {
  0%   {background-color: lightblue;}
  25%  {background-color: lightgreen;}
  50%  {background-color: lightseagreen;}
  100% {background-color: lightgrey;}
}

.resultP1P2P3 {
  font-size: 22px;
}

.LaihuiButtonBoxVersion {
  color: white;
  background-color: #3c96f4;
  width: auto;
  min-width: 50px;
  border: 0px;
  font-weight: bold;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px;
}

.BoxversionButton {
  text-align: center;
  color: white;
  font-weight: bold;
  width: 180px;
}

.ConfirmDeleteContainer {
  width: 100%;
  border-radius: 10px;
  overflow-x: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  padding-top: 20px;
}

.ReceiptHistorySearch {
  text-align: center;
  font-weight: bold;
  color: #303030;
}

.DisplayReceiptContainer {
  text-align: start;
  width: 60%;
  height: 350px;
  font-weight: bold;
  background-color: #efefef;
  padding: 10px;
  overflow-y: scroll;
  border: 1px solid #D3D3D3;
  border-radius: 5px;
}

.StartBackground {
  width: 100%;
  background-color: #efefef;
  height: 100vh;
  flex-direction: column;
  overflow-x: auto;
}

.SelecteduserContainer {
  width: 100%;
  justify-content: space-evenly;
  margin-top: 20px;
  margin-bottom: 20px;
}

.CloseButtonModal {
  position: absolute;
  right: 20px;
  top: 5px;
  cursor: pointer;
}

.ButtonCloseModal {
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
}

.ConfirmDeleteContainer2 {
  width: 100%;
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 20px;
}

.ConfirmDeleteText {
  width: 100%;
  margin-right: 10px;
  font-weight: bold;
  text-align: center;
}

.ConfirmDTextCon {
  flex-direction: row;
  text-align: center;
  margin-bottom: 5px;
}

.ConfirmDButtonContainer {
  width: 100%;
  flex-direction: row;
  text-align: center;
  padding-bottom: 20px;
}

.BoxversionDateButton {
  background-color: #3C96F4;
  color: white;
  font-weight: bold;
}

.BoxversionCheckboxSize {
  width: 16px;
  height: 16px;
}

.RestoreButton {
  border: 1px solid black;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 5px;
}

.HomeSpecialUsertext{
  text-align: center;
  width: 50%;
  padding-left: 5px;
  padding-right: 5px;
  color: black;
  font-weight: bold;
}

.ViewReceiptFunctionsButton {
  color: white;
  font-weight: bold;
  min-width: 70px;
  width: 90%;
  text-align: center;
}

.MoveUpArrow {
  font-size: 30px;
  font-weight: bold;
  cursor: pointer;
}

.Bettingv2PlatformIcon {
  height: 43px;
  width: 43px;
  margin-bottom: 5px;
}

.BoxversionInputNumber {
  border-width: 1px;
  font-weight: bold;
  text-align: center;
  font-size: 18px;
  width: 110px;
}

.BoxversionInputValue {
  border-width: 1px;
  font-weight: bold;
  text-align: center;
  font-size: 18px;
  color: red;
  width: 80px;
}

.BoxVersionReceiptButton{
  background-color: #3C96F4;
  color: white;
  width: auto;
  font-weight: bold;
  margin-bottom: 10px;
}

.DisplayReceiptValue {
  margin-bottom: 30px;
  font-size: 16px;
  white-space: pre-wrap;
  font-weight: bold;
  text-align: start;
  margin-left: 10px;
}

.ReceiptButton {
  text-align: center;
  /* width: 20%; */
}

.PlusLineButton{
  border-radius: 5px;
  border: none;
  background-color: #32c6f4;
  margin-right: 5px;
}

.AdminManageEditButton {
  font-size: 20px;
  align-items: center;
  margin-right: 5px;
  font-weight: bold;
  color: white;
  border-radius: 5px;
  border-width: 0;
  background-color: #006de2;
}

/* Magnum */

.Magnumtittle {
  font-size: 40px;
  font-weight: bold;
  text-align: center;
  color: black;
  background-color: rgba(255, 230, 0, 0.658);
  border: solid black;
}

.plusbtn {
  font-size: 27px;
  background-color: red;
  text-align: center;
  border-color: rgb(0, 0, 0);
  border-style: solid;
}

.or {
  font-size: 30px;
  text-align: center;
  font-weight: bold;
}

/* Damacai */

.Damacaitittle {
  font-size: 40px;
  font-weight: bold;
  text-align: center;
  color: rgb(255, 255, 255);
  background-color: rgb(31, 33, 163);
  border: solid black;
}

/* ToTo */
.Tototittle {
  font-size: 40px;
  font-weight: bold;
  text-align: center;
  color: rgb(255, 255, 255);
  background-color: rgb(194, 25, 25);
  border: solid black;
}

.picture {
  height: 150;
  width: 85;
  border: solid red;
}

.DropzonePackage {

  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #747171;
  border-style: dashed;
  background-color: #fafafa;
  color: #747171;
  outline: none;
  transition: border 0.24s ease-in-out;
}

.Highlight {
  background-color: rgb(188, 185, 236);
}

.SuccessSign{
  width: 70px;
  height: 65px;
}

.SendButton {
  width: 100px !important;
  background-color: black;
  color: white;
}

.DeletePhoneButton:hover {
  box-shadow: 0 12px 16px 0 rgba(27, 27, 27, 0.616),
  0 17px 50px 0 rgba(36, 35, 35, 0.900);
}

.AttentionsLogo{
  width: 100px;
  height: 100px;
}

.ModalMEssage {
  /* border-radius: 20px; */
  box-shadow: 0 12px 16px 0 rgba(27, 27, 27, 0.616),
    0 17px 50px 0 rgba(36, 35, 35, 0.900);
}

.SendButton:hover {
  box-shadow: 0 0 30px rgb(82 76 76 / 50%);
  transition: all 0.5s ease-in-out;
}

.SendButton:active {
  background-color: white;
  color: black;
  /* transition: all 0.5s ease-in-out; */
}

.Receiptarea {
  text-align: start;
  height: 600px;
  font-weight: bold;
  background-color: #efefef;
  padding: 10px;
  color: #525050;
  border-radius: 5px;
}

.scrollhost {
  scrollbar-width: none;
 -ms-overflow-style: none;
}

.scrollhost::-webkit-scrollbar {
  width: 10px;
}

.scrollhost::-webkit-scrollbar-track  {
  background: transparent;
}

.scrollhost::-webkit-scrollbar-thumb   {
  background: rgb(119, 119, 119);
  border-radius: 20px;
}

.scrollhost::-webkit-scrollbar-thumb:hover {
  background: #555;
  cursor: pointer;
}

.ReceiptArea2 {
  width: 60%;
  overflow-y: scroll;
  border: 1px solid #D3D3D3;
}

/* Singapora */
.Sgtittle {
  font-size: 40px;
  /* font-size: calc(20px + 1vmin); */
  font-weight: bold;
  text-align: center;
  color: rgb(15, 15, 15);
  background-color: rgb(34, 201, 243);
  border: solid black;
}

/* BoomTown */
.Boomtowntittle {
  font-size: 40px;
  font-weight: bold;
  text-shadow: -3px 0 rgb(255, 255, 255), 0 2px rgb(255, 255, 255),
    1px 0 rgb(255, 255, 255), 0 -1px rgb(255, 255, 255);
  font-family: sans;
  color: rgb(255, 0, 0);
  text-align: center;
  background-color: rgb(0, 0, 0);
  border: solid rgb(0, 0, 0);
}

/* 9lotto */
.Ninelottotittle {
  font-size: 40px;
  font-weight: bold;
  text-shadow: -3px 0 rgb(255, 255, 255), 0 2px rgb(255, 255, 255),
    1px 0 rgb(255, 255, 255), 0 -1px rgb(255, 255, 255);
  font-family: sans;
  color: rgb(255, 115, 0);
  text-align: center;
  background-color: rgb(0, 0, 0);
  border: solid rgb(0, 0, 0);
}

/* Sabah88 */
.Sabahtittle {
  font-size: 40px;
  font-weight: bold;
  text-align: center;
  color: rgb(255, 255, 255);
  background-color: rgb(4, 0, 255);
  border: solid black;
}

.FindNumberOptionsLList {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
}

/* Stc */
.Stctittle {
  font-size: 40px;
  font-weight: bold;
  text-align: center;
  color: rgb(34, 116, 34);
  background-color: rgb(255, 187, 0);
  border: solid black;
}

/* Cash */
.Cashtittle {
  font-size: 36.4px;
  font-weight: bold;
  text-align: center;
  color: rgb(255, 255, 255);
  background-color: rgb(34, 116, 34);
  border: solid black;
}

.border {
  background-color: rgb(0, 0, 0);
  color: white;
  font-weight: bold;
  font-size: 25px;
  text-align: center;
}

.border3 {
  background-color: none;
  font-weight: 500;
  text-align: center;
  color: black;
  font-size: 25px;
  border: (204, 204, 204);
}

.resulttop {
  font-size: 28px;
  color: #303030;
  font-weight: 700;
  text-align: center;
  border-color: #ccc;
  border-style: solid;
  border-width: 1px;
  padding: 2px 0;
}

.resultbottom {
  text-align: center;
  font-size: 24px;
  color: #000;
  width: 20%;
  border-color: #ccc;
  border-style: solid;
  border-width: 1px;
  padding: 3px 0;
  font-weight: bold;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.footer {
  margin-top: 1rem;
  padding: 1rem;
  background-color: #474747;
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  padding-bottom: 0px;
  width: 100%;
}

.App-link {
  color: #61dafb;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.dropdown-toggle,
.dropdown-menu {
  width: 100%;
}

.form-check input[type="checkbox"] {
  height: 20px;
  width: 20px;
  position: relative;
  margin: 0px 5px 0px 0px;
}

.DateModalButton {
  color: black;
  font-weight: bold;
  font-size: 18px;
  flex-direction: row;
  /* padding: 8px; */
}

.BetN {
  color: blue;
  font-weight: bold;
  font-size: 17px;
}

.BetNFormatDisplayNumber {
  font-size: 12px;
  top: 0;
  left: 0;
  color: red;
  font-weight: bold;
}

.BoxVersionKeyBettingInput {
  border-width: 1;
  width: 100px;
  color: red;
  font-weight: bold;
  text-align: center;
}

.StyleDatePickerMobile {
  border: 1px solid #b9b9b9;
  font-size: 15px;
  text-align: center;
  width: 100%;
  padding: 4px;
}

.ReportDetails {
  color: black;
  position: relative;
}

.FileInput {
  display: none;
}

.Dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #747171;
  border-style: dashed;
  background-color: #fafafa;
  color: #747171;
  outline: none;
  transition: border 0.24s ease-in-out;
}

.DropzonePackage {

  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #747171;
  border-style: dashed;
  background-color: #fafafa;
  color: #747171;
  outline: none;
  transition: border 0.24s ease-in-out;
}

.Highlight {
  background-color: rgb(188, 185, 236);
}

/* BettingVersion2 */
.TitleBettingVersion {
  /* margin-bottom: 30px; */
  margin-bottom: 0px;
}

.jackpotborder {
  border-radius: 5px;
  height: 45px;
  background-color: #003986;
  width: 100%;
  color: white;
  font-size: 20px;
}

.TitleDate {
  font-weight: bold;
  font-size: 18px;
}

.BoxversionInputStyle {
  color: red;
}

.TablePadding {
  padding-right: 1%;
}
.MenubarTitle {
  font-size: 16px;
}

.ENMenubarTitle {
  font-size: 16px;
}

.EditBetNumberInput {
  width: 100px;
  margin-right: 10px;
  font-weight: bold;
}

.HomeMobileButton{
  text-align: center;
  background-color: #3C96F4;
  color: white;
  width: 30%;
  height: 80px;
  font-weight: bold;
}

.HomePageTitle{
  color: black;
  font-weight: bold;
  padding: 2px;
  font-size: calc(10px + 1vmin);
}

.TextFontSize{
  font-size: 16px;
}

.BoxversionPlatFont{
  font-size: 15px;
}

.HeaderFontSize{
  font-size: 18px;
}

.HomePageTitleSize{
  font-size: 18px;
}

.HeaderNavbarWidth {
  width: 200px;
}

.HitSalesSEarchInput{
  width: 250px;
  text-align: center;
  font-weight: bold;
  color: #303030;
}

.testingblur {
  position: relative;
  width: 100%;
  height: 100vh;
  /* overflow: hidden; */
  /* overflow-x: auto; */
}

.testingblur::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  /* width: 100%;
  height: 100%; */
  background-image: url('https://static.vecteezy.com/system/resources/previews/038/093/737/non_2x/ai-generated-nature-landscapes-background-free-photo.jpg'); 
  background-size: cover;
  background-repeat:no-repeat; 
  filter: blur(10px); /* Adjust the blur radius as needed */
  z-index: -1;
  /* overflow: auto; */
}

.contesttesting {
  position: relative;
  z-index: 1;
  padding: 20px;
}

.Testingblurhover {
  border: 2px solid lightgrey;
  cursor: pointer;
}

.Testingblurhover:hover {
  /* border: 2px solid rgb(69, 69, 255); */
  border: 2px solid rgb(0, 59, 185);
  box-shadow: 0 0 20px rgba(0, 59, 185, 0.829);
  /* box-shadow: 0 12px 16px 0 rgb(53, 53, 250), */
}

@media (max-width: 1800px) {
  .setBoostrap {
    position: absolute;
    width: 1600px;
    left: -200px;
  }
  .setBoostrapSmall {
    position: absolute;
    width: 1300px;
    left: -200px;
  }
  .MenubarTitle {
    font-size: 16px;
  }
  .HeaderNavbarWidth {
    width: 200px;
  }
  .DropdownListBoxV {
    font-size: 14px;
    width: 100px;
  }
  .BoxversionInputValue {
    width: 78px;
    font-size: 17px;
  }
  .BoxversionInputNumber {
    width: 110px;
    font-size: 17px;
  }
  .BoxversionCheckboxSize {
    width: 16px;
    height: 16px;
  }
  .TextFontSize {
    font-size: 16px;
  }
  .BoxversionPlatFont{
    font-size: 14px;
  }
  .HeaderFontSize{
    font-size: 18px;
  }
  .HomePageTitleSize{
    font-size: 18px;
  }
  .resultP1P2P3 {
    font-size: 22px;
  }
  .DisplayEditData {
    font-size: 16px;
  }
  .numberborderdspecon {
    font-size: 22px;
  }
  .textalignment {
    font-size: 20px;
  }
  .textalignmen3t {
    font-size: 20px;
  }
  .logomagnumimages {
    width: 140px;
    height: 70px;
  }
  .number5Dborderdspecon {
    font-size: 19px;
  }
  .numberbordergorjackport {
    font-size: 23px;
  }
  .ViewReceiptFont {
    font-size: 18px;
  }
  .jackpotborder {
    height: 44px;
    font-size: 19px;
  }
  .jackpotborderRedBet {
    height: 44px;
    font-size: 19px;
  }
  
  .jackpotborderSgd {
    height: 44px;;
    font-size: 19px;
  }
  .TitleBettingVersion {
    margin-bottom: 0px;
    /* margin-bottom: 29px; */
  }
  .TitleUsername {
    font-size: 17px;
    width: 100px;
    margin-left: "4.5%";
    margin-right: 50px;
  }
  .TitleDate {
    font-size: 17px;
  }
  .TitleUsername {
    font-size: 17px;
  }
  .EditBetNumberInput {
    width: 93px;
  }
}

@media (max-width: 2000px) {
  .Header {
    width: 200px;
    margin-left: 20px;
  }
  .MenubarTitle {
    font-size: 16px;
  }
  .HeaderNavbarWidth {
    width: 200px;
  }
  .TextFontSize {
    font-size: 15px;
  }
  .BoxversionInputNumber {
    width: 110px;
    font-size: 16px;
  }
  .DropdownListBoxV {
    font-size: 14px;
  }
  .BoxversionInputValue {
    width: 78px;
    font-size: 16px;
  }
  .BoxversionCheckboxSize {
    width: 16px;
    height: 16px;
  }
  .BoxversionPlatFont{
    font-size: 14px;
  }
  .HeaderFontSize{
    font-size: 18px;
  }
  .HomePageTitleSize{
    font-size: 18px;
  }
  .LogoutText {
    font-size: 20px;
    color: white;
    cursor: pointer;
  }
  .Report {
    width: 1200px;
  }
  .VIPUserWidth {
    padding: 0px 10px;
    width: fit-content;
    height: fit-content;
  }
}

@media (max-width: 1600px) {
  .setBoostrap {
    position: absolute;
    width: 1500px;
    left: -180px;
  }
  .setBoostrapSmall {
    position: absolute;
    width: 1200px;
    left: -180px;
  }
  .BoxversionButton{
    width: 180px;
  }
  .HitSalesSEarchInput{
    width: 220px;
  }
  .BoxversionInputNumber {
    width: 100px;
    font-size: 15px;
  }
  .DropdownListBoxV {
    font-size: 14px;
    width: 100px;
  }
  .HeaderNavbarWidth {
    width: 190px;
  }
  .BoxversionInputValue {
    width: 75px;
    font-size: 14px;
  }
  .BoxversionCheckboxSize {
    width: 16px;
    height: 16px;
  }
  .MenubarTitle {
    font-size: 15px;
  }
  .TextFontSize {
    font-size: 15px;
  }
  .BoxversionPlatFont{
    font-size: 13px;
  }
  .HomePageTitleSize{
    font-size: 17px;
  }
  .HeaderFontSize{
    font-size: 17px;
  }
  .resultP1P2P3 {
    font-size: 21px;
  }
  .numberborderdspecon {
    font-size: 20px;
  }
  .textalignment {
    font-size: 19px;
  }
  .textalignmen3t {
    font-size: 19px;
  }
  .logomagnumimages {
    width: 140px;
    height: 70px;
  }
  .number5Dborderdspecon {
    font-size: 17px;
  }
  .numberbordergorjackport {
    font-size: 21px;
  }
  .ViewReceiptFont {
    font-size: 17px;
  }
  .DisplayEditData {
    font-size: 15px;
  }
  .jackpotborder {
    height: 41px;
    font-size: 17px;
  }
  .jackpotborderRedBet {
    height: 41px;
    font-size: 17px;
  }
  
  .jackpotborderSgd {
    height: 41px;;
    font-size: 17px;
  }
  .TitleBettingVersion {
    margin-bottom: 0px;
    /* margin-bottom: 26px; */
  }
  .TitleDate {
    font-size: 16px;
  }
  .TitleUsername {
    font-size: 16px;
  }
  .EditBetNumberInput {
    width: 85px;
  }
}

@media (max-width: 1400px) {
  .setBoostrap {
    position: absolute;
    width: 1300px;
    left: -70px;
  }
  .setBoostrapSmall {
    position: absolute;
    width: 1000px;
    left: -70px;
  }
  .BoxversionButton{
    width: 170px;
  }
  .HitSalesSEarchInput{
    width: 200px;
  }
  .DropdownListBoxV {
    font-size: 14px;
    width: 100px;
  }
  .HeaderNavbarWidth {
    width: 170px;
  }
  .BoxversionInputValue {
    width: 68px;
    font-size: 14px;
  }
  .BoxversionInputNumber {
    width: 90px;
    font-size: 14px;
  }
  .BoxversionCheckboxSize {
    width: 16px;
    height: 16px;
  }
  .MenubarTitle {
    font-size: 14px;
  }
  .TextFontSize {
    font-size: 13px;
  }
  .BoxversionPlatFont{
    font-size: 13px;
  }
  .HomePageTitleSize{
    font-size: 16px;
  }
  .HeaderFontSize{
    font-size: 16px;
  }
  .DisplayEditData {
    font-size: 14px;
  }
  .resultP1P2P3 {
    font-size: 21px;
  }
  .numberborderdspecon {
    font-size: 18px;
  }
  .logomagnumimages {
    width: 110px;
    height: 60px;
    justify-content: center;
    justify-items: center;
    align-items: center;
  }
  .numberbordergorjackport {
    font-size: 18px;
  }
  .jackpotborder {
    height: 38px;
    font-size: 16px;
  }
  .jackpotborderRedBet {
    height: 38px;
    font-size: 16px;
  }
  
  .jackpotborderSgd {
    height: 38px;;
    font-size: 16px;
  }
  .TitleBettingVersion {
    /* margin-bottom: 24px; */
    margin-bottom: 0px;
  }
  .TitleDate {
    font-size: 14px;
  }
  .TitleUsername {
    font-size: 16px;
  }
  .EditBetNumberInput {
    width: 70px;
  }
}

@media (max-width: 1300px) {
  .TextFontSize {
    font-size: 13px;
  }
  .BoxversionButton{
    width: 150px;
  }
  .HitSalesSEarchInput{
    width: 180px;
  }
  .BoxversionPlatFont{
    font-size: 13px;
  }
  .HeaderFontSize{
    font-size: 16px;
  }
  .HomePageTitleSize{
    font-size: 16px;
  }
  .DropdownListBoxV {
    font-size: 13px;
    width: 100px;
  }
  .MenubarTitle {
    font-size: 14px;
  }
  .BoxversionInputValue {
    width: 65px;
    font-size: 13px;
  }
  .BoxversionInputNumber {
    width: 88px;
    font-size: 13px;
  }
}

@media (max-width: 1200px) {
  .setBoostrap {
    position: absolute;
    width: 1100px;
    left: -150px;
  }
  .setBoostrapSmall {
    position: absolute;
    width: 800px;
    left: -150px;
  }
  .TextFontSize {
    font-size: 13px;
  }
  .BoxversionPlatFont{
    font-size: 12px;
  }
  .resultP1P2P3 {
    font-size: 20px;
  }
  .numberborderdspecon {
    font-size: 15px;
  }
  .textalignment {
    font-size: 17px;
  }
  .textalignmen3t {
    font-size: 17px;
  }
  .logomagnumimages {
    width: 90px;
    height: 50px;
    justify-content: center;
    justify-items: center;
    align-items: center;
  }
  .number5Dborderdspecon {
    font-size: 18px;
  }
  .numberbordergorjackport {
    font-size: 16px;
  }
  .jackpotborder {
    height: 36px;
    font-size: 15px;
  }
  .jackpotborderRedBet {
    height: 36px;
    font-size: 15px;
  }
  
  .jackpotborderSgd {
    height: 36px;;
    font-size: 15px;
  }
  .TitleBettingVersion {
    margin-bottom: 0px;
    /* margin-bottom: 22px; */
  }
  .TitleDate {
    font-size: 15px;
  }
  .EditBetNumberInput {
    width: 60px;
  }
}

@media (max-width: 600px) {
  .setBoostrap {
    position: absolute;
    width: 570px;
    left: -35px;
  }
  .setBoostrapSmall {
    position: absolute;
    width: 650px;
    left: -35px;
  }
  .BoxversionButton{
    width: 50%;
  }
  .TextFontSize {
    font-size: 12px;
  }
  .BettingV2Width {
    width: 100%;
  }
  .DisplayEditData {
    font-size: 16px;
  }
  .resultP1P2P3 {
    font-size: 21px;
  }
  .numberborderdspecon {
    font-size: 18px;
  }
  .textalignment {
    font-size: 19px;
  }
  .textalignmen3t {
    font-size: 19px;
  }
  .ViewReceiptFont {
    font-size: 16px;
  }
  .logoimages {
    width: 300;
    height: 250;
    border-radius: 50px;
  }
  .LoginInputText {
    text-align: center;
    width: auto;
    height: 30px;
    /* border-radius: 20px; */
  }
}
